<template>
  <div class="col-12">
    <div>
      <div class="col-12" v-if="withFieldsFiltering">
        <el-collapse width="100%">
          <el-collapse-item title="Columns Selector" name="fields">
            <p />
            <el-checkbox
              :disabled="visibleColumns.length == 1 && column.visible"
              v-for="(column, index) in tableColumns"
              :key="index"
              v-model="column.visible"
            >
              {{ column.label }} </el-checkbox
            >&nbsp;
          </el-collapse-item>
        </el-collapse>
      </div>
      <p />
      <div
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <el-select
          v-if="paginated"
          class="select-default mb-1"
          style="width: 100px"
          v-model="pagination.perPage"
          placeholder="Per page"
        >
          <el-option
            class="select-default"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <div class="col-5" v-if="withDateTimeRange">
          <el-date-picker
            v-model="dateTimeRange"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            align="left"
          >
          </el-date-picker>
        </div>

        <el-input
          v-if="searchable"
          type="search"
          class="mb-3"
          style="width: 200px"
          placeholder="Search records"
          v-model="searchQuery"
          aria-controls="datatables"
        />
      </div>
      <div class="col-sm-12">
        <el-table
          class="hover"
          stripe
          style="width: 100%;"
          :data="queriedData"
          border
          highlight-current-row
          @row-click="handleCurrentChange"
          @select="handleSelection"
          :row-key="getRowKeys"
          ref="tableData"
        >
          <!--<el-table-column v-if="withMultiSelection"
                            :min-width="40"
                            label="Monitor">
            <template slot-scope="props">
              {{props.row.id}}
              <input type="checkbox" v-model="props.row.MONITOR" @change="handleSelection(props.row)" :key="props.row.id"/>
            </template>
          </el-table-column>-->

          <el-table-column v-if="withStatus" :min-width="80" label="Status">
            <template slot-scope="props">
              <a
                v-if="props.row.status == 'Success'"
                class="btn-success btn-simple btn-link"
                ><i class="fa fa-check"></i
              ></a>
              <a v-else class="btn-danger btn-simple btn-link"
                ><i class="fa fa-times"></i
              ></a>
            </template>
          </el-table-column>

          <el-table-column
            v-for="(column, index) in visibleColumns"
            sortable
            :key="index"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
            :formatter="column.formatter"
          >
            <template slot-scope="scope">
              <el-input
                size="small"
                v-model="scope.row[column.prop]"
                v-if="
                  (editedIndex == scope.$index || column.editable) &&
                    column.editType == 'input' &&
                    column.textType == 'Text'
                "
                v-on:input="handleTextChange(scope.row)"
                :disabled="editedIndex != scope.$index && withActions"
              >
              </el-input>
              <el-input
                size="small"
                v-else-if="
                  (editedIndex == scope.$index || column.editable) &&
                    column.editType == 'input' &&
                    column.textType == 'Number'
                "
                type="number"
                v-model.number="scope.row[column.prop]"
                v-on:input="handleTextChange(scope.row)"
                :disabled="editedIndex != scope.$index && withActions"
              >
              </el-input>
              <el-select
                class="select-primary"
                style="width: 100%"
                :disabled="editedIndex != scope.$index && withActions"
                v-model="scope.row[column.prop]"
                @change="handleSelectionChange(scope.row)"
                filterable
                clearable
                v-else-if="
                  (editedIndex == scope.$index || column.editable) &&
                    column.editType == 'select'
                "
              >
                <el-option
                  class="select-primary"
                  v-for="item in column.selectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <span v-else>{{ dateFormatter(scope.row, column) }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :min-width="50"
            fixed="right"
            label="Actions"
            v-if="withActions"
          >
            <template slot-scope="props">
              <a
                v-if="editedIndex == null"
                v-tooltip.top-center="'Edit'"
                class="btn-warning btn-simple btn-link"
                @click="handleEdit(props.$index, props.row)"
                ><i class="fa fa-edit"></i
              ></a>
              <a
                v-else-if="editedIndex == props.$index"
                v-tooltip.top-center="'Save'"
                class="btn-success btn-simple btn-link"
                @click="handleSave(props.$index, props.row)"
                ><i class="fa fa-check"></i
              ></a>
              <a
                v-tooltip.top-center="'Delete'"
                class="btn-danger btn-simple btn-link"
                @click="handleDelete(props.$index, props.row)"
                ><i class="fa fa-times"></i
              ></a>
            </template>
          </el-table-column>

          <el-table-column type="expand" v-if="expandable">
            <el-table
              class="hover"
              stripe
              style="width: 100%;"
              height="250"
              :data="expandedTableData"
              border
              highlight-current-row
            >
              <el-table-column
                v-for="(column, index) in expandedTableColumns"
                sortable
                :key="index"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
                :formatter="column.formatter"
              >
              </el-table-column>
            </el-table>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p v-if="paginated" class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
        <button
          v-if="withAddingRows"
          type="button"
          class="btn btn-default btn-outline"
          v-on:click="addRow()"
        >
          <i class="nc-icon custom-icon nc-simple-add"></i>
          {{ AddbuttonText }}</button
        >&nbsp;
      </div>
      <l-pagination
        v-if="paginated"
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="pagination.total"
      >
      </l-pagination>
    </div>
  </div>
</template>
<script>
import {
  DatePicker,
  TimeSelect,
  Table,
  TableColumn,
  Select,
  Option,
  Checkbox,
  Collapse,
  CollapseItem
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import users from "src/pages/Dashboard/Tables/users.js";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import Vue from "vue";
import moment from "moment";
import Card from "../../../components/Cards/Card.vue";

export default {
  name: "pg-table",
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect
  },
  computed: {
    visibleColumns() {
      return this.tableColumns.filter(column => column.visible);
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (this.tableData == null || this.tableData.length == 0)
        return this.tableData;

      let resultQueried = this.tableData;

      if (this.searchQuery !== "") {
        resultQueried = this.searchByText(this.searchQuery, resultQueried);
      } else if (this.dateTimeRange !== "") {
        const intervalStart = this.dateTimeRange[0].getTime();
        const intervalEnd = this.dateTimeRange[1].getTime();
        resultQueried = searchByDate(intervalStart, intervalEnd, resultQueried);
      }

      this.pagination.total = resultQueried.length;

      if (this.preSelected) {
        this.$nextTick(() => {
          for (let i = 0; i < this.tableData.length; i++) {
            this.$refs.tableData.toggleRowSelection(this.tableData[i]);
          }
        });
      }

      return resultQueried.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    handleClick() {
      if (typeof this.handleCurrentChange === "function") {
        return this.handleCurrentChange.bind(this);
      }
      return () => {
        this.handleEdit();
      };
    }
  },
  data() {
    return {
      getRowKeys(row) {
        return row.id;
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      dateTimeRange: "",
      searchQuery: "",
      fuseSearch: null,
      alertResult: null,
      editedIndex: null
    };
  },
  props: {
    tableColumns: Array,
    tableData: Array,
    expandedTableColumns: Array,
    expandedTableData: Array,
    propsToSearch: {
      type: Array,
      default: () => {
        return [];
      }
    },
    handleCurrentChange: {
      type: Function,
      default: () => {}
    },
    handleSelection: {
      type: Function,
      default: () => {}
    },
    handleTextChange: {
      type: Function,
      default: () => {}
    },
    handleSelectionChange: {
      type: Function,
      default: () => {}
    },
    VENUE_DRINK_ID: 0,
    withStatus: false,
    withDateTimeRange: false,
    expandable: false,
    editable: false,
    withActions: false,
    withAddingRows: false,
    AddbuttonText: "",
    withMultiSelection: false,
    preSelected: false,
    paginated: false,
    searchable: false,
    withFieldsFiltering: false
  },
  methods: {
    searchByText(query, data) {
      let resultFilteredByQuery = data.filter(item => {
        for (let i = 0; i < this.propsToSearch.length; i++) {
          let element = this.propsToSearch[i];
          if (typeof item[element] != "undefined") {
            let valid =
              item[element].toLowerCase().match(query.toLowerCase()) !== null;
            if (valid === true) return true;
          }
        }
        return false;
      });
      return resultFilteredByQuery;
    },
    searchByDate(from, to, data) {
      let resultFilteredbyDate = [];
      for (let i = 0; i < data.length; i++) {
        const modTime = new Date(data[i].time);
        if (modTime.getTime() <= to && modTime.getTime() >= from) {
          resultFilteredbyDate.push(data[i]);
        }
      }
      return resultFilteredbyDate;
    },
    expandRow(row) {
      this.$refs.tableData.toggleRowExpansion(row);
    },
    addRow() {
      this.editedIndex = this.queriedData.length;
      this.tableData.push({ VENUE_DRINK_ID: this.VENUE_DRINK_ID });
    },
    handleEdit(index, row) {
      this.editedIndex = index;
    },
    handleSave(index, row) {
      this.editedIndex = null;
    },
    handleDelete(index, row) {
      this.showSwal("warning-message-and-cancel", index, row);
    },
    showSwal(type, index, row) {
      if (type === "warning-message-and-cancel") {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            reverseButtons: false
          })
          .then(result => {
            if (result.isConfirmed) {
              this.$emit("on-deleteRow", row);
              this.tableData.splice(index, 1);
              swalWithBootstrapButtons.fire(
                "Deleted!",
                "The Venue has been deleted.",
                "success"
              );
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              swalWithBootstrapButtons.fire(
                "Cancelled",
                "The Venue is safe",
                "error"
              );
            }
          });
      }
    },
    dateFormatter(row, column) {
      if (column.prop == "time") {
        let date = row[column.prop];
        if (date == undefined) {
          return "";
        }
        return moment(date).format("DD-MM-YYYY HH:mm:ss");
      }
      return row[column.prop];
    }
  },
  mounted() {},
  created() {}
};
</script>
<style>
.hover {
  cursor: pointer;
}
</style>
